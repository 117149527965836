
        <template>
          <div class="vc-snippet-doc">
            <h2>一、代码静态检查工具</h2>
<h3>1.1、使用 eslint 工具对 javascript 代码进行检查</h3>
<p><code>eslint</code> 检查的规范继承自<code> eslint-config-standard</code> 检验规则，具体的规则介绍参照链接：<a href="https://cn.eslint.org/docs/rules/">cn.eslint.org/docs/rules/</a> ，这里及以下部分不再重复介绍这些检验规则。</p>
<h3>1.2、使用 stylelint 工具对 css 样式代码进行检查</h3>
<p><code>stylelint</code> 检查的规范继承自 <code>stylelint-config-standard </code>检验规则，具体的规则介绍参照链接：<a href="https://www.npmjs.com/package/stylelint-config-standard">www.npmjs.com/package/sty…</a> ，这里及以下部分不再重复介绍这些检验规则。</p>
<h2>二、命名规范</h2>
<h3>2.1、JS 采用 Camel Case 小驼峰式命名</h3>
<p>推荐：</p>
<pre class="hljs"><code>studentInfot
</code></pre>
<h3>2.2、避免名称冗余</h3>
<p>推荐：</p>
<pre class="hljs"><code>const Car = {
  make: &quot;Honda&quot;,
  model: &quot;Accord&quot;,
  color: &quot;Blue&quot;
};
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>const Car = {
  carMake: &quot;Honda&quot;,
  carModel: &quot;Accord&quot;,
  carColor: &quot;Blue&quot;
};
</code></pre>
<h3>2.3、CSS 类名采用 BEM 命名规范</h3>
<p>推荐：</p>
<pre class="hljs"><code>.block__element{} 
.block--modifier{}
</code></pre>
<h3>2.4、命名符合语义化</h3>
<p>命名需要符合语义化，如果函数命名，可以采用加上动词前缀：</p>
<p>动词 含义can 判断是否可执行某个动作has 判断是否含有某个值is 判断是否为某个值get 获取某个值set 设置某个值
推荐：</p>
<pre class="hljs"><code>//是否可阅读 
functioncanRead(){ 
   returntrue; 
} 
//获取姓名 
function getName{
   return this.name 
}
</code></pre>
<h2>三、JS 推荐写法</h2>
<h3>3.1、每个常量都需命名</h3>
<p>每个常量应该命名，不然看代码的人不知道这个常量表示什么意思。</p>
<p>推荐：</p>
<pre class="hljs"><code>const COL_NUM = 10;
let row = Math.ceil(num/COL_NUM);
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>let row = Math.ceil(num/10);
</code></pre>
<h3>3.2、推荐使用字面量</h3>
<p>创建对象和数组推荐使用字面量，因为这不仅是性能最优也有助于节省代码量。</p>
<p>推荐：</p>
<pre class="hljs"><code>let obj = {   
     name:'tom',     
     age:15,     
     sex:'男' 
}
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>let obj = {};
obj.name = 'tom';
obj.age = 15;
obj.sex = '男';
</code></pre>
<h3>3.3、对象设置默认属性的推荐写法</h3>
<p>推荐：</p>
<pre class="hljs"><code>const menuConfig = {
  title: &quot;Order&quot;,
  // User did not include 'body' key
  buttonText: &quot;Send&quot;,
  cancellable: true
};

function createMenu(config) {
  config = Object.assign(
    {
      title: &quot;Foo&quot;,
      body: &quot;Bar&quot;,
      buttonText: &quot;Baz&quot;,
      cancellable: true
    },
    config
  );

  // config now equals: {title: &quot;Order&quot;, body: &quot;Bar&quot;, buttonText: &quot;Send&quot;, cancellable: true}
  // ...
}

createMenu(menuConfig);
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>const menuConfig = {
  title: null,
  body: &quot;Bar&quot;,
  buttonText: null,
  cancellable: true
};

function createMenu(config) {
  config.title = config.title || &quot;Foo&quot;;
  config.body = config.body || &quot;Bar&quot;;
  config.buttonText = config.buttonText || &quot;Baz&quot;;
  config.cancellable =
    config.cancellable !== undefined ? config.cancellable : true;
}

createMenu(menuConfig);
</code></pre>
<h3>3.4、将对象的属性值保存为局部变量</h3>
<p>对象成员嵌套越深，读取速度也就越慢。所以好的经验法则是：如果在函数中需要多次读取一个对象属性，最佳做法是将该属性值保存在局部变量中，避免多次查找带来的性能开销。</p>
<p>推荐：</p>
<pre class="hljs"><code>let person = {
    info:{
        sex:'男'
    }
}
function getMaleSex(){
    let sex = person.info.sex;
    if(sex === '男'){
        console.log(sex)
    }
}
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>let person = {
    info:{
        sex:'男'
    }
}
function getMaleSex(){
    if(person.info.sex === '男'){
        console.log(person.info.sex)
    }
}
</code></pre>
<h3>3.5、字符串转为整型</h3>
<p>当需要将浮点数转换成整型时，应该使用<code>Math.floor()</code>或者<code>Math.round()</code>，而不是使用<code>parseInt()</code>将字符串转换成数字。<code>Math 是内部对象，所以``Math.floor()</code>其实并没有多少查询方法和调用时间，速度是最快的。</p>
<p>推荐：</p>
<pre class="hljs"><code>let num = Math.floor('1.6');
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>let num = parseInt('1.6');
</code></pre>
<h3>3.6、函数参数</h3>
<p>函数参数越少越好，如果参数超过两个，要使用 <code>ES6</code>的解构语法，不用考虑参数的顺序。</p>
<p>推荐：</p>
<pre class="hljs"><code>function createMenu({ title, body, buttonText, cancellable }) {
  // ...
}

createMenu({
  title: 'Foo',
  body: 'Bar',
  buttonText: 'Baz',
  cancellable: true
});
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>function createMenu(title, body, buttonText, cancellable) {
  // ...
}
</code></pre>
<h3>3.7、使用参数默认值</h3>
<p>使用参数默认值 替代 使用条件语句进行赋值。</p>
<p>推荐：</p>
<pre class="hljs"><code>function createMicrobrewery(name = &quot;Hipster Brew Co.&quot;) {
  // ...
}
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>function createMicrobrewery(name) {
  const breweryName = name || &quot;Hipster Brew Co.&quot;;
  // ...
}
</code></pre>
<h3>3.8、最小函数准则</h3>
<p>这是一条在软件工程领域流传久远的规则。严格遵守这条规则会让你的代码可读性更好，也更容易重构。如果违反这个规则，那么代码会很难被测试或者重用 。</p>
<h3>3.9、不要写全局方法</h3>
<p>在 <code>JavaScript</code> 中，永远不要污染全局，会在生产环境中产生难以预料的 <code>bug</code>。举个例子，比如你在 <code>Array.prototype</code> 上新增一个 <code>diff</code> 方法来判断两个数组的不同。而你同事也打算做类似的事情，不过他的 <code>diff</code> 方法是用来判断两个数组首位元素的不同。很明显你们方法会产生冲突，遇到这类问题我们可以用 <code>ES2015/ES6 </code>的语法来对 <code>Array</code> 进行扩展。</p>
<p>推荐:</p>
<pre class="hljs"><code>class SuperArray extends Array {
  diff(comparisonArray) {
    const hash = new Set(comparisonArray);
    return this.filter(elem =&gt; !hash.has(elem));        
  }
}
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>Array.prototype.diff = function diff(comparisonArray) {
  const hash = new Set(comparisonArray);
  return this.filter(elem =&gt; !hash.has(elem));
};
</code></pre>
<h3>3.10、推荐函数式编程</h3>
<p>函数式变编程可以让代码的逻辑更清晰更优雅，方便测试。</p>
<p>推荐：</p>
<pre class="hljs"><code>const programmerOutput = [
  {
    name: 'Uncle Bobby',
    linesOfCode: 500
  }, {
    name: 'Suzie Q',
    linesOfCode: 1500
  }, {
    name: 'Jimmy Gosling',
    linesOfCode: 150
  }, {
    name: 'Gracie Hopper',
    linesOfCode: 1000
  }
];
let totalOutput = programmerOutput
  .map(output =&gt; output.linesOfCode)
  .reduce((totalLines, lines) =&gt; totalLines + lines, 0)
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>const programmerOutput = [
  {
    name: 'Uncle Bobby',
    linesOfCode: 500
  }, {
    name: 'Suzie Q',
    linesOfCode: 1500
  }, {
    name: 'Jimmy Gosling',
    linesOfCode: 150
  }, {
    name: 'Gracie Hopper',
    linesOfCode: 1000
  }
];

let totalOutput = 0;

for (let i = 0; i &lt; programmerOutput.length; i++) {
  totalOutput += programmerOutput[i].linesOfCode;
}
</code></pre>
<h3>3.11、使用多态替换条件语句</h3>
<p>为了让代码更简洁易读，如果你的函数中出现了条件判断，那么说明你的函数不止干了一件事情，违反了函数单一原则 ；并且绝大数场景可以使用多态替代</p>
<p>推荐：</p>
<pre class="hljs"><code>class Airplane {
  // ...
}
// 波音777
class Boeing777 extends Airplane {
  // ...
  getCruisingAltitude() {
    return this.getMaxAltitude() - this.getPassengerCount();
  }
}
// 空军一号
class AirForceOne extends Airplane {
  // ...
  getCruisingAltitude() {
    return this.getMaxAltitude();
  }
}
// 赛纳斯飞机
class Cessna extends Airplane {
  // ...
  getCruisingAltitude() {
    return this.getMaxAltitude() - this.getFuelExpenditure();
  }
}
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>class Airplane {
  // ...

  // 获取巡航高度
  getCruisingAltitude() {
    switch (this.type) {
      case'777':
        return this.getMaxAltitude() - this.getPassengerCount();
      case'Air Force One':
        return this.getMaxAltitude();
      case'Cessna':
        return this.getMaxAltitude() - this.getFuelExpenditure();
    }
  }
}
</code></pre>
<h3>3.12、定时器是否清除</h3>
<p>代码中使用了定时器 <code>setTimeout</code> 和 <code>setInterval</code>，需要在不使用时进行清除</p>
<h3>3.13、关于模块</h3>
<p>在非标准模块系统上使用(import/export)</p>
<pre class="hljs"><code><span class="hljs-comment">// bad</span>
<span class="hljs-keyword">const</span> AirbnbStyleGuide = <span class="hljs-built_in">require</span>(<span class="hljs-string">'./AirbnbStyleGuide'</span>)
<span class="hljs-built_in">module</span>.exports = AirbnbStyleGuide.es6

<span class="hljs-comment">// ok</span>
<span class="hljs-keyword">import</span> AirbnbStyleGuide <span class="hljs-keyword">from</span> <span class="hljs-string">'./AirbnbStyleGuide'</span>
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> AirbnbStyleGuide.es6

<span class="hljs-comment">// best</span>
<span class="hljs-keyword">import</span> { es6 } <span class="hljs-keyword">from</span> <span class="hljs-string">'./AirbnbStyleGuide'</span>
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> es6
</code></pre>
<p>复制代码一个入口只 import 一次</p>
<pre class="hljs"><code><span class="hljs-comment">// bad</span>
<span class="hljs-keyword">import</span> foo <span class="hljs-keyword">from</span> <span class="hljs-string">'foo'</span>
<span class="hljs-comment">// … some other imports … //</span>
<span class="hljs-keyword">import</span> { named1, named2 } <span class="hljs-keyword">from</span> <span class="hljs-string">'foo'</span>

<span class="hljs-comment">// good</span>
<span class="hljs-keyword">import</span> foo, { named1, named2 } <span class="hljs-keyword">from</span> <span class="hljs-string">'foo'</span>
</code></pre>
<p>复制代码在只有一个导出的模块里，用 export default 更好</p>
<pre class="hljs"><code><span class="hljs-comment">// bad</span>
<span class="hljs-keyword">export</span> <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">foo</span>(<span class="hljs-params"></span>) </span>{}

<span class="hljs-comment">// good</span>
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">foo</span>(<span class="hljs-params"></span>) </span>{}
</code></pre>
<h2>四、SCSS 推荐写法</h2>
<h3>4.1、变量 $ 使用</h3>
<p>利用<code>scss</code>中的变量配置，可以进行项目的颜色、字体大小统一更改（换肤），有利于后期项目的维护。</p>
<p>推荐：</p>
<pre class="hljs"><code>$--color-success: #67C23A;
$--color-warning: #E6A23C;
$--color-danger: #F56C6C;
$--color-info: #909399;
</code></pre>
<h3>4.2、@import 导入样式文件</h3>
<p><code>scss</code>中的<code>@import</code>规则在生成<code>css</code>文件时就把相关文件导入进来。这意味着所有相关的样式被归纳到了同一个<code>css</code>文件中，而无需发起额外的下载请求，在构建我们自己的组件库时推荐使用。</p>
<pre class="hljs"><code>@import &quot;./base.scss&quot;;
@import &quot;./pagination.scss&quot;;
@import &quot;./dialog.scss&quot;;
@import &quot;./autocomplete.scss&quot;;
@import &quot;./dropdown.scss&quot;;
@import &quot;./dropdown-menu.scss&quot;;
</code></pre>
<h3>4.3、局部文件命名的使用</h3>
<p><code>scss</code>局部文件的文件名以下划线开头。这样，<code>scss</code>就不会在编译时单独编译这个文件输出<code>css</code>，而只把这个文件用作导入。</p>
<p>推荐：</p>
<p><img src="https://user-gold-cdn.xitu.io/2019/7/3/16bb70822f5edd3f?imageView2/0/w/1280/h/960/format/webp/ignore-error/1" alt="" /></p>
<h3>4.4、父选择器标识符 &amp; 实现BEM 命令规范</h3>
<p><code>scss</code>的嵌套和父选择器标识符 &amp; 能解决<code>BEM</code>命名的冗长，且使样式可读性更高。</p>
<p>推荐：</p>
<pre class="hljs"><code>.el-input {
  display: block;
  &amp;__inner {
     text-align: center;
  }
}
</code></pre>
<h3>4.5、@mixin 混合器的使用</h3>
<p><code>mixin</code>混合器用来实现大段样式的重用，减少代码的冗余，且支持传参。</p>
<pre class="hljs"><code>@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  border-radius: $border-radius;
  &amp;.is-round {
    padding: $padding-vertical $padding-horizontal;
  }
}

  @include m(medium) {
    @include button-size($--button-medium-padding-vertical, $--button-medium-padding-horizontal, $--button-medium-font-size, $--button-medium-border-radius);   
  }

  @include m(small) {
    @include button-size($--button-small-padding-vertical, $--button-small-padding-horizontal, $--button-small-font-size, $--button-small-border-radius);
  }
</code></pre>
<h3>4.6、@extend 指令的使用</h3>
<p>（1）使用<code>@extend</code>产生 <a href="http://vanseodesign.com/css/dry-principles/">DRY CSS</a>风格的代码（Don't repeat yourself）</p>
<p>（2）<code>@mixin</code>主要的优势就是它能够接受参数。如果想传递参数，你会很自然地选择<code>@mixin</code>而不是<code>@extend</code></p>
<p>推荐：</p>
<pre class="hljs"><code>.common-mod {
  height: 250px;
  width: 50%;
  background-color: #fff;
  text-align: center;
}

 .show-mod--right {
   @extend .common-mod;
   float: right;
 }

.show-mod--left {
   @extend .common-mod;
}
</code></pre>
<h3>4.7、#{} 插值的使用</h3>
<p>插值能动态定义类名的名称，当有两个页面的样式类似时，我们会将类似的样式抽取成页面混合器，但两个不同的页面样式的命名名称根据<code>BEM</code>命名规范不能一样，这时我们可使用插值进行动态命名。</p>
<p>推荐：</p>
<pre class="hljs"><code>@mixin home-content($class) {
  .#{$class} {
    position: relative;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: hidden;

    &amp;--left {
      margin-left: 160px;
    }

    &amp;--noleft {
      margin-left: 0;
    }
  }
}
</code></pre>
<h3>4.8、each遍历、map数据类型、@mixin/@include混合器、#{}插值 结合使用</h3>
<p>可通过<code>each</code>遍历、<code>map</code>数据类型、<code>@mixin/@include</code>混合器、#{} 插值 结合使用，从而减少冗余代码，使代码更精简。</p>
<p>推荐：</p>
<pre class="hljs"><code>$img-list: (
   (xlsimg, $papers-excel),
   (xlsximg, $papers-excel),
   (gifimg, $papers-gif),
   (jpgimg, $papers-jpg),
   (mp3img, $papers-mp3),
   (mp4img, $papers-mp3),
   (docimg, $papers-word),
   (docximg, $papers-word),
   (rarimg, $papers-zip),
   (zipimg, $papers-zip),
   (unknownimg, $papers-unknown)
);

@each $label, $valuein$img-list {
  .com-hwicon__#{$label} {
    @include commonImg($value);
  }
}
</code></pre>
<h3>4.9、scss 自带函数的应用</h3>
<p><code>scss</code>自带函数的应用，从而进行相关的计算，例如 <code>mix</code>函数的使用如下。</p>
<pre class="hljs"><code>@include m(text) {
    &amp;:hover,
    &amp;:focus {
      color: mix($--color-white, $--color-primary, $--button-hover-tint-percent);
      border-color: transparent;
      background-color: transparent;
    }

    &amp;:active {
      color: mix($--color-black, $--color-primary, $--button-active-shade-percent);
      border-color: transparent;
      background-color: transparent;
    }
}
</code></pre>
<h2>五、Vue 推荐写法</h2>
<h3>5.1、组件名为多个单词</h3>
<p>我们开发过程中自定义的组件的名称需要为多个单词，这样做可以避免跟现有的以及未来的<code>HTML</code>元素相冲突，因为所有的 <code>HTML</code> 元素名称都是单个单词的。</p>
<p>推荐：</p>
<pre class="hljs"><code>Vue.component('todo-item', {
  // ...
})

export default {
  name: 'TodoItem',
  // ...
}
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>Vue.component('todo', {
  // ...
})

export default {
  name: 'Todo',
  // ...
}
</code></pre>
<h3>5.2、组件的 data 必须是一个函数</h3>
<p>当在组件中使用 <code>data</code> 属性的时候 (除了 <code>new Vue</code> 外的任何地方)，它的值必须是返回一个对象的函数。 因为如果直接是一个对象的话，子组件之间的属性值会互相影响。</p>
<p>推荐：</p>
<pre class="hljs"><code>export default {
  data () {
    return {
      foo: 'bar'
    }
  }
}
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>export default {
  data: {
    foo: 'bar'
  }
}
</code></pre>
<h3>5.3、Prop定义应该尽量详细</h3>
<p><code>prop</code> 的定义应该尽量详细，至少需要指定其类型。</p>
<p>推荐：</p>
<pre class="hljs"><code>props: {
  status: String
}

// 更好的做法！
props: {
  status: {
    type: String,
    required: true,
    validator: function (value) {
      return [
        'syncing',
        'synced',
        'version-conflict',
        'error'
      ].indexOf(value) !== -1
    }
  }
}
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>props: ['status']
</code></pre>
<h3>5.4、为 v-for 设置键值</h3>
<p><code>v-for </code>中总是有设置 <code>key</code> 值。在组件上总是必须用 <code>key</code> 配合 <code>v-for</code>，以便维护内部组件及其子树的状态。</p>
<h3>5.5、完整单词的组件名</h3>
<p>组件名应该倾向于完整单词而不是缩写，编辑器中的自动补全已经让书写长命名的代价非常之低了，而其带来的明确性却是非常宝贵的。不常用的缩写尤其应该避免。</p>
<p>推荐：</p>
<pre class="hljs"><code>components/ 
|- StudentDashboardSettings.vue 
|- UserProfileOptions.vue
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>components/ 
|- SdSettings.vue 
|- UProfOpts.vue
</code></pre>
<h3>5.6、多个特性元素的每个特性分行</h3>
<p>在 <code>JavaScript</code> 中，用多行分隔对象的多个属性是很常见的最佳实践，因为这样更易读。</p>
<p>推荐：</p>
<pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">MyComponent</span>
  <span class="hljs-attr">foo</span>=<span class="hljs-string">"a"</span>
  <span class="hljs-attr">bar</span>=<span class="hljs-string">"b"</span>
  <span class="hljs-attr">baz</span>=<span class="hljs-string">"c"</span>
/&gt;</span>
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">MyComponent</span> <span class="hljs-attr">foo</span>=<span class="hljs-string">"a"</span> <span class="hljs-attr">bar</span>=<span class="hljs-string">"b"</span> <span class="hljs-attr">baz</span>=<span class="hljs-string">"c"</span>/&gt;</span>
</code></pre>
<h3>5.7、模板中简单的表达式</h3>
<p>组件模板应该只包含简单的表达式，复杂的表达式则应该重构为计算属性或方法。复杂表达式会让你的模板变得不那么声明式。我们应该尽量描述应该出现的是什么，而非如何计算那个值。而且计算属性和方法使得代码可以重用。</p>
<p>推荐：</p>
<pre class="hljs"><code>&lt;!-- 在模板中 --&gt;
{{ normalizedFullName }}

<span class="hljs-comment">// 复杂表达式已经移入一个计算属性</span>
<span class="hljs-attr">computed</span>: {
  <span class="hljs-attr">normalizedFullName</span>: <span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params"></span>) </span>{
    <span class="hljs-keyword">return</span> <span class="hljs-keyword">this</span>.fullName.split(<span class="hljs-string">' '</span>).map(<span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params">word</span>) </span>{
      <span class="hljs-keyword">return</span> word[<span class="hljs-number">0</span>].toUpperCase() + word.slice(<span class="hljs-number">1</span>)
    }).join(<span class="hljs-string">' '</span>)
  }
}
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>{{
  fullName.split(<span class="hljs-string">' '</span>).map(<span class="hljs-function"><span class="hljs-keyword">function</span> (<span class="hljs-params">word</span>) </span>{
    <span class="hljs-keyword">return</span> word[<span class="hljs-number">0</span>].toUpperCase() + word.slice(<span class="hljs-number">1</span>)
  }).join(<span class="hljs-string">' '</span>)
}}
</code></pre>
<h3>5.8、简单的计算属性</h3>
<p>应该把复杂计算属性分割为尽可能多的更简单的属性。</p>
<p>推荐：</p>
<pre class="hljs"><code>computed: {
  basePrice: function () {
    return this.manufactureCost / (1 - this.profitMargin)
  },
  discount: function () {
    return this.basePrice * (this.discountPercent || 0)
  },
  finalPrice: function () {
    return this.basePrice - this.discount
  }
}
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>computed: {
  price: function () {
    var basePrice = this.manufactureCost / (1 - this.profitMargin)
    return (
      basePrice -
      basePrice * (this.discountPercent || 0)
    )
  }
}
</code></pre>
<h3>5.9、指令缩写</h3>
<p>指令推荐都使用缩写形式，(用 : 表示<code> v-bind:</code> 、用 @ 表示<code>v-on:</code>和用 # 表示 <code>v-slot:</code>)。</p>
<p>推荐：</p>
<pre class="hljs"><code>&lt;input
  @input=&quot;onInput&quot;
  @focus=&quot;onFocus&quot;
&gt;
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code>&lt;input
  v-on:input=&quot;onInput&quot;
  @focus=&quot;onFocus&quot;
&gt;
</code></pre>
<h3>5.10、标签顺序保持一致</h3>
<p>单文件组件应该总是让标签顺序保持为 <code>&lt;template&gt; </code>、<code>&lt;script&gt;</code>、 <code>&lt;style&gt;</code>  。</p>
<p>推荐：</p>
<pre class="hljs"><code><span class="hljs-comment">&lt;!-- ComponentA.vue --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>...<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="actionscript"><span class="hljs-comment">/* ... */</span></span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">style</span>&gt;</span><span class="css"><span class="hljs-comment">/* ... */</span></span><span class="hljs-tag">&lt;/<span class="hljs-name">style</span>&gt;</span>
</code></pre>
<p>不推荐：</p>
<pre class="hljs"><code><span class="hljs-comment">&lt;!-- ComponentA.vue --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>...<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">style</span>&gt;</span><span class="css"><span class="hljs-comment">/* ... */</span></span><span class="hljs-tag">&lt;/<span class="hljs-name">style</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="actionscript"><span class="hljs-comment">/* ... */</span></span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
<h3>5.11、组件之间通信</h3>
<p>父子组件的通信推荐使用 <code>prop</code>和 <code>emit</code> ，而不是<code>this.$parent</code>或改变 <code>prop</code>；</p>
<p>兄弟组件之间的通信推荐使用 <code>EventBus（on）</code>，而不是滥用 <code>vuex</code>；</p>
<p>祖孙组件之间的通信推荐使用<code>listeners</code>  或<code> provide / inject</code>（依赖注入） ，而不是滥用 <code>vuex</code>；</p>
<h3>5.12、页面跳转数据传递</h3>
<p>页面跳转，例如 A 页面跳转到 B 页面，需要将 A 页面的数据传递到 B 页面，推荐使用 路由参数进行传参，而不是将需要传递的数据保存 vuex，然后在 B 页面取出 vuex的数据，因为如果在 B 页面刷新会导致 vuex 数据丢失，导致 B 页面无法正常显示数据。</p>
<p>推荐：</p>
<pre class="hljs"><code>let id = ' 123';
this.$router.push({name: 'homeworkinfo', query: {id:id}});
</code></pre>
<h3>5.13、script 标签内部声明顺序</h3>
<p><code>script</code> 标签内部的声明顺序如下：</p>
<pre class="hljs"><code>components &gt; mixins &gt; prop &gt; data &gt; computed &gt;  watch &gt; 
钩子函数（钩子函数按其执行顺序） &gt; filter &gt; methods
</code></pre>
<h3>5.14、计算属性 VS 方法 VS 侦听器</h3>
<ul>
<li>
<p>（1）推荐使用计算属性：计算属性基于响应式依赖进行缓存，只在相关响应式依赖发生改变时它们才会重新求值；相比之下，每次调用方法都会再次执行方法；</p>
</li>
<li>
<p>（2）推荐使用计算属性：而不是根据 <code>Watch</code> 侦听属性，进行回调； 但是有计算属性做不到的：当需要在数据变化时执行异步或开销较大的操作时，侦听器是最有用的。</p>
</li>
</ul>
<h3>5.15、v-if  VS  v-show</h3>
<ul>
<li>
<p><code>v-if </code>是“真正”的条件渲染，因为它会确保在切换过程中条件块内的事件监听器和子组件适当地被销毁和重建。 <code>v-if</code> 也是惰性的：如果在初始渲染时条件为假，则什么也不做——直到条件第一次变为真时，才会开始渲染条件块。</p>
</li>
<li>
<p>相比之下，<code>v-show </code>就简单得多——不管初始条件是什么，元素总是会被渲染，并且只是简单地基于 <code>CSS</code> 的属性 <code>display</code> 进行切换。</p>
</li>
</ul>
<p>推荐：</p>
<p>如果运行时，需要非常频繁地切换，推荐使用 <code>v-show </code>比较好；如果在运行时，条件很少改变，则推荐使用 <code>v-if </code>比较好。</p>
<h2>六、团队其它规范</h2>
<h3>6.1、尽量不手动操作 DOM</h3>
<p>因为团队现在使用 <code>vue</code> 框架，所以在项目开发中尽量使用 <code>vue</code> 的特性去满足我们的需求，尽量（不到万不得已）不要手动操作<code>DOM</code>，包括：增删改<code>dom</code>元素、以及更改样式、添加事件等。</p>
<h3>6.2、删除弃用代码</h3>
<p>很多时候有些代码已经没有用了，但是没有及时去删除，这样导致代码里面包括很多注释的代码块，好的习惯是提交代码前记得删除已经确认弃用的代码，例如：一些调试的<code>console</code>语句、无用的弃用代码。</p>
<h3>6.3、保持必要的注释</h3>
<p>代码注释不是越多越好，保持必要的业务逻辑注释，至于函数的用途、代码逻辑等，要通过语义化的命令、简单明了的代码逻辑，来让阅读代码的人快速看懂。</p>
<h2>借鉴</h2>
<blockquote>
<p>https://juejin.im/post/5d1c6550518825330a3bfa01#heading-32</p>
<p>https://juejin.im/post/5f02c8eff265da22c058e050#heading-20</p>
</blockquote>

          </div>
        </template>
        <script>
           
           export default {
           name: 'vc-component-doc',
           components: {
            
           }
         }
       </script>
       <style lang='scss'>
         
       </style>